export default [
  {
    header: 'common.menu_group.analytics_management',
  },
  {
    title: 'pages.home.title',
    route: 'home',
    icon: 'FileIcon',
  },
  {
    title: 'pages.calendar.title',
    route: 'calendar',
    icon: 'FileIcon',
  },  
  {
    header: 'common.menu_group.crm_management',
  },
  {
    title: 'pages.prospect.title',
    route: 'list-prospects',
    icon: 'FileIcon',
  },  
  {
    title: 'pages.prospect.title-inactive',
    route: 'list-prospects-inactive',
    icon: 'FileIcon',
  },
  {
    title: 'pages.customer.title-waiting',
    route: 'list-customers-waiting',
    icon: 'FileIcon',
  },  
  {
    title: 'pages.customer.validated',
    route: 'list-customers-validated',
    icon: 'FileIcon',
  }, 
  {
    title: 'pages.customer.legal',
    route: 'list-customers-legal',
    icon: 'FileIcon',
  }, 
  {
    title: 'pages.customer.accounting',
    route: 'list-customers-accounting',
    icon: 'FileIcon',
  },  
  {
    title: 'pages.package.title',
    route: 'list-packages',
    icon: 'FileIcon',
  },  
  {
    header: 'common.menu_group.blog_management',
  },
  {
    title: 'pages.category.title',
    route: 'list-categories',
    icon: 'FileIcon',
  },
  {
    title: 'pages.article.title',
    route: 'list-articles',
    icon: 'FileIcon',
  }, 
  {
    title: 'pages.comment.title',
    route: 'list-comments',
    icon: 'FileIcon',
  }, 
  {
    header: 'common.menu_group.static_page_management',
  },
  {
    title: 'pages.static_page.title',
    route: 'list-static-pages',
    icon: 'FileIcon',
  }, 
  {
    header: 'common.menu_group.content_management',
  },
  {
    title: 'pages.page.title',
    route: 'list-pages',
    icon: 'FileIcon',
  },
  {
    title: 'pages.category_document.title',
    route: 'list-category-documents',
    icon: 'FileIcon',
  },
 
  {
    title: 'pages.document.title',
    route: 'list-documents',
    icon: 'FileIcon',
  },
 
  {
    title: 'pages.faq.title',
    route: 'list-faqs',
    icon: 'FileIcon',
  },   
  {
    title: 'pages.client.title',
    route: 'list-clients',
    icon: 'FileIcon',
  },  
  {
    title: 'pages.configure.title',
    route: 'list-configures',
    icon: 'FileIcon',
  },   
  
]
